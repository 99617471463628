<template>
  <div>

    <b-card>
      <rc-overlay :show="loading">
        <b-form-group>
          <b-form-checkbox-group
            v-model="checklist"
          >
            <b-row v-if="vehicle">
              <b-col
                v-for="(checklistItem) in vehicle.checklistItems"
                :key="checklistItem.id"
                cols="12"
                class="mb-50"
              >
                <b-form-checkbox :value="checklistItem.checklistDictionary.id">
                  <span class="font-medium-2">{{ checklistItem.checklistDictionary.value }}</span>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-form-group>

        <label>
          {{ $t('team.checklist.comment') }}
        </label>
        <b-form-textarea
          v-model="comment"
          :placeholder="$t('team.checklist.comment-placeholder')"
        />
      </rc-overlay>
      <template #footer>

        <div class="text-right">

          <b-button
            variant="primary"
            :disabled="loading || !canConfirm"
            @click="confirmChecklist"
          >
            {{ $t('shared.button.submit') }}
          </b-button>
        </div>

      </template>
    </b-card>
  </div>
</template>

<script>
import {
  transformDatetimeToHumanReadablePretty, empty, parseRequestError,
} from '@/helpers/helpers'
import {
  BButton,
  BCard, BCol, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormTextarea, BRow,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BButton,
    BFormTextarea,

    RcOverlay,
  },
  data() {
    return {
      loading: false,
      vehicle: null,
      comment: null,
      checklist: [],
    }
  },
  computed: {
    myTeam() {
      return this.$store.getters['auth/getUserTeam']
    },
    canConfirm() {
      if (!this.vehicle) {
        return false
      }

      return this.vehicle.checklistItems.length === this.checklist.length
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    init() {
      if (empty(this.myTeam) || !this.myTeam.id || this.myTeam.checklistConfirmedAt) {
        this.$router.push('/')
        return
      }

      this.loading = true
      this.$store.dispatch('vehicle/fetchVehicleById', this.myTeam.vehicle.id)
        .then(res => {
          this.vehicle = res.data
          this.vehicle.checklistItems.forEach((item, index) => {
            if (!item.checklistDictionary.active) {
              this.vehicle.checklistItems.splice(index, 1)
            }
          })
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    confirmChecklist() {
      const team = {
        checklistConfirmedAt: moment().toISOString(),
        checklistComment: this.comment,
      }
      this.$store.dispatch('team/updateTeam', { id: this.myTeam.id, data: team })
        .then(() => {
          window.toast.notify.success(this.$t('team.checklist.save-success'))
          this.$store.dispatch('auth/fetchUserTeam')
          this.$router.push('/')
        })
        .catch(err => parseRequestError(err))
    },
  },
}
</script>
